@import url('https://fonts.googleapis.com/css2?family=Lato:wght@300&display=swap');

:root {
  --primary-color: #047aed;
  --secondary-color: #00a4f5;
  --dark-color: #002240;
  --light-color: #f4f4f4;
  --success-color: #5cb85c;
  --error-color: #d9534f;
  --heading-color: #060606;
  --bg-grey: #f4f4f4;
  --border-color-light: #eee;
  --border-radius: 8px;
  --box-shadow: 0 0.5rem 1rem hsla(0, 0%, 5%, .05);
  --bs-font-sans-serif: system-ui, -apple-system, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  --box-shadow-red-color: #ff0000;
  --banner-layer: hsla(0, 0%, 100%, 0.227);
}


* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

body {
  /*font-family: 'Lato', sans-serif;*/
  font-family: var(--bs-font-sans-serif);
  color: #333;
  line-height: 1.6;
}

ul {
  list-style-type: none;
}

a {
  text-decoration: none;
  color: #333;
}

p {
  margin: 10px 0;
  text-align: justify;
  text-justify: inter-word;
}
.align-items-start p {
  margin: 0 0 10px;
}

li>a {
  text-decoration: none;
  color: white
}

.social>a>i {
  text-decoration: none;
  color: white
}

h1, h2 {
  font-weight: 300px;
  line-height: 1.2;
  margin: 10px 0;
}

.sub-menu {
  display: none;
}
i{
  color: var(--secondary-color);
}
.active i{
  color: black;
}

.navbar ul li:hover .sub-menu {
  display: block;
  position: absolute;
  overflow: visible;
  background-color: white;
}
.navbar-1 ul li:hover .sub-menu {
  display: block;
  position: absolute;
  overflow: visible;
  background-color: var(--primary-color);
}

.navbar ul li:hover .sub-menu a {
  padding: 0 5px;
  margin: 0 10px 5px;
}

.navbar {
  background-color: white;
  color: black;
  font-weight: 500;
  height: 80px;
}
.navbar-1 {
  background-color: var(--primary-color);
  color: white;
  height: 80px;
}

.navbar .flex {
  justify-content: space-between;
}

.navbar ul.main-menu {
  display: flex;
}

.navbar a {
  color: black;
  padding: 10px;
  margin: 0 5px;
}
.navbar-1 a {
  color: white;
  padding: 10px;
  margin: 0 5px;
}

.navbar a:hover {
  border-bottom: 3px solid var(--secondary-color);
  font-size: 1.1em;
}

.container {
  max-width: 1350px;
  margin: 0 auto;
  overflow: auto;
  padding: 0 20px;
  gap: 30px;

}

.flex {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;  
}
.flex-border{
  border:1px solid var(--secondary-color);
}

.j-flex{
  justify-content: center;
  flex-wrap: wrap;
}

.flex-1 {
  gap: 20px;
}

.d-flex {
  display: flex !important
}

.footer .social a {
  margin: 0 10px;
}

.footer {
  position: relative;
  bottom: 0;
  width: 100%;
  margin-top: 20px;
}

.grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 20px;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.grid-gap-10 {
  gap: 10px;
}

.grid-align {
  align-items: none;
}
.align-items-start{
  align-items: start;
}

.grid-3 {
  grid-template-columns: repeat(3, 1fr);
}
.grid-4 {
  grid-template-columns: repeat(4, 1fr);
}

.grid-1 {
  grid-template-columns: none;
}

.container-1 {
  max-width: 1400px;
  margin: 0 auto;
  overflow: auto;
  padding: 0 20px;

}

.bg-dark, .btn-dark {
  background-color: var(--dark-color);
  color: white;
}

.features-sub-head img {
  width: 100%;
  height: 400px;
  justify-self: flex-start;
  margin-right: 20px;
  position: relative;
}

.features-main .card>i {
  margin-right: 20px;
}

.features-main .grid {
  padding: 20px;
}

/*.features-main .grid>*:first-child {
  grid-column: 1 / span 3;
}*/

/*.features-main .grid>*:nth-child(2) {
  grid-column: 1 / span 1;
}*/

.values h1 {
  text-align: center;
  margin-top: 20px;
}

.title {
  border: 2px solid none;
  height: 100px;
  width: 100%;
  margin-top: 0;
  margin-bottom: 10px;
  background-color: white
}

.title h1 {
  text-align: center;
  color: black;
  padding: 20px;
}

.contact-form input, .contact-form textarea {
  width: 100%;
  color: var(--heading-color);
  background: var(--bg-grey);
  font-size: 16px;
  padding: 14px;
  border: 2px solid var(--border-color-light);
  outline: none;
  margin-bottom: 10px !important;
  border-radius: var(--border-radius)
}

.contact-form textarea {
  height: 180px;
  margin: 0 0 20px 0;
  font-family: la;
}

.required::before {
  content: " *";
  color: red;
  top: 0;
  left: 0;
}

*:required{
  border: 1px solid red !important;
}
form input.btn-submit:hover,form input#btn-cancel:hover {
  cursor: pointer;
}

form input.btn-submit {
  background-color: var(--secondary-color);
  color: white;
}
#btn-cancel{
  background-color: var(--secondary-color);
  color: white;

}

.contact-left {
  box-shadow: var(--box-shadow);
  padding: 40px
}

.cont-left.text-center i {
  color: var(--secondary-color);
  font-size: 26px
}

.cont-details h6 {
  font-size: 18px;
  font-weight: 600;
  line-height: 18px;
  margin-bottom: 8px
}

.text-start {
  text-align: left !important
}

.text-center {
  text-align: center !important
}

.btn-style-3 {
  background: var(--secondary-color)
}
.company-name h6{
  font-size: 25px;
  text-decoration: none;
}
.company-name{
  margin-bottom: 20px;
  }
  .logo{
    font-size: 40px;
    text-decoration: underline;
    text-decoration-color: red;
  }

  .shadow-box .card p{
    /*box-shadow: 1px 1px .2em red, -1px -1px .2em red;*/
    box-shadow:0.4rem 0.4rem 0.5rem hsla(0, 0%, 5%, .05),-0.4rem -0.4rem 0.5rem hsla(0, 0%, 5%, .05);
    padding: 10px;
  }
  .shadow-box .card p:nth-child(2){
    box-shadow:0.2rem 0.2rem 0.2rem var(--box-shadow-red-color),-0.2rem -0.2rem 0.2rem var(--box-shadow-red-color);
  }

  
  .shadow-box .card p{
    margin: 10px 5px 5px;
  }
  .job-card {
    display: grid;
    border: 1px solid var(--secondary-color);
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    padding: 10px;
    margin: 10px;
    width: 300px;
    height: 300px;
    background-color: #fff;
  }
  .job-card.overflow-scroll {
    overflow: scroll;
  }
  /* Card Title */
  .job-card h2 {
    font-size: 1.5rem;
    margin-bottom: 8px;
    color: #333;
  }
  
  /* Card Content */
  .job-card p {
    font-size: 1rem;
    color: #666;
  }
  .job-card li{
    list-style-type:disc;
    margin-left: 20px;
  }
  .job-card .skills span{
    margin: 0 2px;
  }
  .job-card button {
    display: flex;
    background-color: var(--secondary-color);
    color: white;
    padding: 10px 20px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 1rem;
    justify-self: center;
    margin-top: auto;
  }
  
  /* Hover effect for the button */
  .job-card button:hover {
    background-color: #45a049;
  }
  .horizontal-list {
    list-style-type:disc;
    padding: 0;
    display: flex;
    flex-wrap: wrap; /* Use flexbox to arrange items horizontally */
  }
  
  /* List Item Styling */
  .horizontal-list li {
    margin-right: 5px; /* Add spacing between list items */
  }

  .overlay-styles{
    position: fixed;
    margin-top: 0;
    margin-left: 0;
    margin-right: 0;
    margin-bottom: 0;
    background-color: rgba(0, 0, 0, .7);
    z-index: 1000;
  }
  .modalContainer .footer {
    flex: 20%;
    display: flex;
    justify-content: right;
    align-items: right;
  }
  
  .modalContainer .footer button {
    width: 75px;
    height: 25px;
    margin: 10px;
    border: none;
    background-color: cornflowerblue;
    color: white;
    border-radius: 4px;
    font-size: 12px;
    cursor: pointer;
  }
  
  .form-m-p{
    margin: 10px auto;
    padding: 10px;
  }
  .form-m-p input{
    padding: 10px;
  }
    
  .modalContainer .body {
    flex: 50%;
    display: flex;
    justify-content: left;
    align-items: center;
    font-size: 12px;
    text-align: left;
    color: black;
  }
  .modalContainer .modal-title {
    display: inline-block;
    text-align: left;
    margin-top: 0px;
    color: white;
    background-color: var(--secondary-color);
    border-radius: 4px;
    padding: 10px;
    font-size: 14px;
    }
  .modalContainer {
    width: 500px;
    height: auto;
    border-radius: 12px;
    background-color: white;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    display: flex;
    flex-direction: column;
    padding: 0px;
    margin-top: 10px;
  }
  .modal-styles{
    position: fixed;
    margin-top: 40%;
    margin-left: 50%;
    transform: translate(-50%, -50%);
    background-color: #960d0d;
    padding: 0px;
    z-index: 1000;
  }
.mx-1 {
  margin: 0 1rem;
}

.mx-2 {
  margin: 0 1.5rem;
}

.mx-3 {
  margin: 0 2rem;
}

.mx-4 {
  margin: 0 3rem;
}

.mx-5 {
  margin: 0 3rem;
}

.my-1 {
  margin: 1rem 0;
}

.my-2 {
  margin: 1.5rem 0;
}

.my-3 {
  margin: 2rem 0;
}

.my-4 {
  margin: 3rem 0;
}

.my-5 {
  margin: 3rem 0;
}

.m-1 {
  margin: 1rem;
}

.m-2 {
  margin: 1.5rem;
}

.m-3 {
  margin: 2rem;
}

.m-4 {
  margin: 3rem;
}

.m-5 {
  margin: 3rem;
}

.me-3 {
  margin-right: 1rem !important
}

/* padding */
.px-1 {
  padding: 0 1rem;
}

.px-2 {
  padding: 0 1.5rem;
}

.px-3 {
  padding: 0 2rem;
}

.px-4 {
  padding: 0 3rem;
}

.px-5 {
  padding: 0 3rem;
}

.py-1 {
  padding: 1rem 0;
}

.py-2 {
  padding: 1.5rem 0;
}

.py-3 {
  padding: 2rem 0;
}

.py-4 {
  padding: 3rem 0;
}

.py-5 {
  padding: 3rem 0;
}

.p-1 {
  padding: 1rem;
}

.p-2 {
  padding: 1.5rem;
}

.p-3 {
  padding: 2rem;
}

.p-4 {
  padding: 3rem;
}

.p-5 {
  padding: 3rem;
}

.pt-3 {
  padding-top: 1rem !important
}

.cloud .grid {
  grid-template-columns: 4fr 3fr;
}

.sm {
  font-size: 1rem;
}

.md {
  font-size: 2rem;
}

.lg {
  font-size: 3rem;

}

.xl {
  font-size: 3rem;

}
/* Custom styles for PaginationComponent */

/* Style for the pagination container */
.pagination {
  display: flex;
  justify-content: center;
  margin-top: 20px;
}

/* Style for pagination items */
.pagination .page-link {
  color: white;
  background-color: var(--secondary-color);  
  border: 1px solid #dee2e6; /* Add a border around the page numbers */
  padding: 0 10px;
  font-size: 1.5em; /* Add padding for better appearance */ /* Bootstrap primary color for links */
}

/* Style for active page */
.pagination .page-item.active .page-link {
  background-color: orangered; /* Bootstrap primary color for active state */
  border-color: #007bff;
}

/* Style for hover effect on page items */
.pagination .page-item:hover .page-link {
  background-color: #e9ecef; /* Bootstrap background color for hover state */
}

/* Style for disabled page items */
.pagination .page-item.disabled .page-link {
  color: white;
  background-color: #6c757d; /* Bootstrap text color for disabled state */
  pointer-events: none; /* Disable click events on disabled items */
}

span.visually-hidden{
  display: none;
}

.inner-banner {
  background: url(./assests/images/banner.jpg) no-repeat 50%;
  background-size: cover;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  -ms-background-size: cover;
  position: relative;
  z-index: -1;
  margin-top: 20px;
  border-top: 1px solid black;
  border-bottom: 1px solid black;
}

.inner-banner:before {
  content: "";
  position: absolute;
  top: 0;
  min-height: 100%;
  left: 0;
  right: 0;
  z-index: -1;
  background: var(--banner-layer)
}
.w3l-breadcrumb {
  display: block;
  margin: 0 auto;
  z-index: 100;
  z-index: 99
}

.breadcrumbs-custom-path {
  text-align: center;
  display: inline-block;
  margin-top: 6px
}
.py-lg-5 {
  padding-top: 3rem!important;
  padding-bottom: 3rem!important
}
.container-banner {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto
}

@media (min-width: 992px) {
  .container-banner {
      max-width:960px;
      margin-right: auto;
      margin-left: auto
  }
}

@media (min-width: 1200px) {
  .container-banner {
      max-width:1140px;
      margin-right: auto;
      margin-left: auto
  }
}

@media (min-width: 1280px) {
  .container-banner {
      max-width:1200px
  }
}
.pt-4 {
  padding-top: 1.5rem!important
}
.pb-sm-4 {
  padding-bottom: 1.5rem!important
}
h4.inner-text-title {
  color: var(--heading-color);
  font-size: 32px;
  line-height: 40px;
  font-weight: 700
}
.pt-5 {
  padding-top: 3rem!important
}
.bx {
  font-family: boxicons!important;
  font-weight: 400;
  font-style: normal;
  font-variant: normal;
  line-height: 1;
  display: inline-block;
  text-transform: none;
  speak: none;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale
}
.bxs-chevron-right:before {
  content: "\ec8f"
}
.breadcrumbs-custom-path li {
  position: relative;
  display: inline-block;
  vertical-align: middle;
  font-size: 15px;
  text-transform: capitalize;
  color: var(--font-color);
  font-weight: 400
}

.breadcrumbs-custom-path li i {
  font-size: 12px;
  margin: 0 5px
}

.breadcrumbs-custom-path a,.breadcrumbs-custom-path a:active {
  color: var(--heading-color);
  font-weight: 700
}

.breadcrumbs-custom-path a:hover {
  color: var(--primary-color)
}

div.responsibilities{
  margin-top: 20px;
}
div.description,li.role{
  text-align: justify;
}

img.company-logo{
  width: 150px;
  border-radius: 20px;
}

/* For WebKit browsers */
::-webkit-scrollbar {
  width: 12px; /* Width of the scrollbar */
}

::-webkit-scrollbar-track {
  background-color: #f1f1f1; /* Color of the track */
}

::-webkit-scrollbar-thumb {
  background-color: #888; /* Color of the thumb */
  border-radius: 6px; /* Rounded corners of the thumb */
}

::-webkit-scrollbar-thumb:hover {
  background-color: #555; /* Color of the thumb on hover */
}

/* For Firefox */
/* Note: Firefox uses scrollbar-width and scrollbar-color instead of individual pseudo-elements */
/* Note: These properties may change in future versions of Firefox */
* {
  scrollbar-width: thin;
  scrollbar-color: #888 #f1f1f1;
}

/* For Edge and IE */
/* Note: These properties may change in future versions of Edge and IE */
* {
  -ms-overflow-style: scrollbar;
}















/* Tablets & under */
@media(max-width: 768px){
  .grid,
  .grid-3,
  .showcase .grid,
  .stats .grid,
  .cli .grid,
  .cloud .grid,
  .features-main .grid .grid-3,
  .docs-main .grid {
    grid-template-columns: 1fr;
    grid-template-rows: 1fr;
  }

  .features-main .grid > *:first-child{
      grid-column: 1 ;
  }
  .features-main .grid > *:nth-child(2){
      grid-column: 1 ;
  }

  .card .flex{
      flex-direction: column;
  }
  .showcase {
    height: auto;
  }

  .showcase-text {
    text-align: center;
    margin-top: 40px;
    animation: slideInFromTop 1s ease-in;
  }

  .showcase-form {
    justify-self: center;
    margin: auto;
    animation: slideInFromBottom 1s ease-in;
  }

  .cli .grid > *:first-child {
    grid-column: 1;
    grid-row: 1;
  }

  .docs-head img, .features-head img, .features-sub-head img{
      justify-self: center;
  }
  .title h1 {
    text-align: center;
    color: black;
    padding: 10px;
    margin-bottom: 20px;
  }
  p{
    padding: 10px 10px 10px;
  }
  .xl {
    font-size: 1.5rem;
}
.md {
  font-size: 1.5rem;
}
.showcase-form {
  width: 300px;
}
.sub-menu{
  z-index: 1
}
.title {
  height: 50px;
}
.features-sub-head img {
  height: 200px;
}
.contact-form textarea {
  height: 100px;
}
.modalContainer {
  width: 300px;
  height: 600px;
  margin-top: 0;
}
.modalContainer .grid {
  gap: 0px;
}
}
/* Mobile */
@media(max-width: 500px){
  .navbar {
      height: 150px;
    }
  
    .navbar .flex {
      flex-direction: column;
      height: auto;
    }
  
    .container{
      gap:20px;
    }
    .navbar ul {
      padding: 10px;
      background-color: rgba(0, 0, 0, 0.1);
    }
    .sub-menu{
      z-index: 1
    }
    
   
}